<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="订单编号:">
                    <el-input v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                </el-form-item>
                <el-form-item label="分账编号:">
                    <el-input v-model="queryParams.profit_sharing_no" placeholder="请输入分账编号" clearable></el-input>
                </el-form-item>
                <el-form-item label="支付流水id:">
                    <el-input v-model="queryParams.mp_transaction_id" placeholder="请输入支付流水id" clearable></el-input>
                </el-form-item>
                <el-form-item label="订单起止时间:" prop="time">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="订单编号(可选):">
                        <el-input v-model="profitOrderApplyFormData.trade_no" placeholder="请输入订单编号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="订单起止时间:" prop="time">
                        <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="profitOrderApplyFormData.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="mpShopPayProfitSharingOrder">批量分账</el-button>
                        <el-button type="primary" @click="payOrderGet">查询支付流水id</el-button>
                        <el-button @click="payOrderGetList" type="primary">获取微信订单列表</el-button>
                        <el-button type="warning" @click="syncAllOrder">同步所有订单（慎用）</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable" style="margin-top:20px" etype="expand">
                <el-table-column prop="ID" label="id" width="80" align="center"></el-table-column>
                <el-table-column prop="uid" label="用户id" width="80" align="center"></el-table-column>
                <el-table-column prop="ordersn" label="订单编号" width="200" align="center"></el-table-column>
                <el-table-column prop="profit_sharing_no" label="分账编号" width="200" align="center"></el-table-column>
                <el-table-column prop="mp_transaction_id" label="支付流水编号" width="200" align="center"></el-table-column>
                <el-table-column prop="mch_id" label="商户id" width="100" align="center"></el-table-column>
                <el-table-column prop="amount" label="分账金额(分)" width="120" align="center"></el-table-column>
                <el-table-column prop="CreatedAt" label="订单时间" width="150" align="center"></el-table-column>
                <el-table-column prop="remark" label="订单备注" width="150" align="center"></el-table-column>
                <el-table-column prop="result" label="分账结果" width="150" align="center"></el-table-column>
                <el-table-column label="分账状态" align="center">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 0">发起</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">失败</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">成功</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center" width="150" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="tiao(scope.$index, scope.row)">订单详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 开发中... -->
        <div class="top">
<!--            <h1>交易组件 </h1>
            <el-row class="box" :gutter="20">
                <el-col :span="5"   >
                    <el-input v-model="order_get_out_order_id" ></el-input><el-button  type="primary" @click="orderGet">获取订单详情</el-button>
                </el-col>
              <el-col :span="3"   >
                <el-button  type="primary" @click="orderGetList">获取订单列表</el-button>
              </el-col>
                <el-col :span="5"   >
                    <el-input v-model="delivery_receive_out_order_id" ></el-input><el-button  type="primary" @click="mpShopDeliveryReceiveOrder">订单收货</el-button>
                </el-col>
            </el-row>
            <br>

            <el-divider/>
            <br>
            <h1>小程序支付管理 </h1>
            <el-row class="box" :gutter="10">
                <el-col :span="3"   ><el-button @click="mpShopSubmitWithdraw" type="primary">发起提现</el-button></el-col>
                <el-col :span="3"   ><el-button @click="mpGetBankAccount" type="primary">查询结算账户</el-button></el-col>
                <el-col :span="5"   >
                    <el-input v-model="profit_order_id" ></el-input><el-button  type="primary" @click="mpShopPayProfitSharingOrder">分账测试</el-button>
                </el-col>
                <el-col :span="3"   ><el-button @click="payOrderGetList" type="primary">获取微信订单列表</el-button></el-col>
            </el-row>
            <br>-->
        </div>


    </div>
</template>

<script>
import {request} from '@/common/request';
import {deal} from "@/common/main";
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';


export default {
    components: {
        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            menuList: ['资金', '小程序支付分账'],
            order_get_out_order_id:"",
            delivery_receive_out_order_id:"",
            profit_order_id:'',
            order_id:"",
            pageInfo: {},
            loading:false,
            profitOrderApplyFormData:{trade_no:"",time:[]}
        }
    },
    created() {
        console.log(this.$router)
        this.loadData()
    },
    methods: {
        changeQuery() {
            this.queryParams.page = 1;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            if (this.queryParams.time) {
                let arr = Object.assign({}, this.queryParams.time)
                this.queryParams.ctime = deal.timestamp(arr[0]);
                this.queryParams.etime = deal.timestamp(arr[1]);
            } else {
                delete this.queryParams.ctime
                delete this.queryParams.etime
            }
            this.loadData()
        },
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/payment/mpshop/pay/profitorderlist', this.queryParams).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        goDetail(path) {
            this.$router.push({ path: path })
        },

        mpShopSubmitWithdraw(){
            this.loading = true;
            let _this = this
            request.postJson('/payment/mpshop/submitwithdraw', {amount:100}).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.common_menu = ret.data.common_menu;
                    this.mattersList = ret.data.stay
                    this.statisticsList1 = ret.data.complete
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        mpGetBankAccount(){
            this.loading = true;
            let _this = this
            request.postJson('/payment/mpshop/getbankaccount', {}).then(ret => {
                if (ret.code === 1) {
                    this.loading = false;
                    this.common_menu = ret.data.common_menu;
                    this.mattersList = ret.data.stay
                    this.statisticsList1 = ret.data.complete
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        orderGet(){
          let _this = this
          request.get('/payment/mpshop/orderget', {out_order_id:_this.order_get_out_order_id}).then(ret => {
            if (ret.code === 1) {

            } else {
              this.$message.error(ret.msg);
            }
          });
        },
        payOrderGet(){
            let _this = this
            if (_this.profitOrderApplyFormData.trade_no.length<=10){
                this.$message.error("请先填写完整的单号");
                return  false
            }
            request.get('/payment/mpshop/pay/orderget', {trade_no:_this.profitOrderApplyFormData.trade_no}).then(ret => {
                if (ret.code === 1) {

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        orderGetList(){
            this.loading = true;
            let _this = this
            request.get('/payment/mpshop/ordergetlist', {}).then(ret => {
                if (ret.code === 1) {

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        payOrderGetList(){
            let _this = this
            let  data={begin_create_time:0,end_create_time:0}
            if  (this.profitOrderApplyFormData.time.length===0){
                this.$message.error("请选择连续的两个日期");
                return false
            }
            let arr = Object.assign({}, this.profitOrderApplyFormData.time)
            data.begin_create_time = deal.timestamp(arr[0]);
            data.end_create_time = deal.timestamp(arr[1]);
            request.get('/payment/mpshop/pay/ordergetlist', data).then(ret => {
                if (ret.code === 1) {

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        syncAllOrder(){
            let  data={created_at_min:0,created_at_max:0}
            if  (this.profitOrderApplyFormData.time.length===0){
                this.$message.error("请选择两个日期");
                return false
            }

            data.created_at_min =  this.profitOrderApplyFormData.time[0]
            data.created_at_max =  this.profitOrderApplyFormData.time[1]
            request.get('/payment/mpshop/pay/syncAllOrder', data).then(ret => {
                if (ret.code === 1) {
                    this.$message.success(ret.msg);
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        mpShopPayProfitSharingOrder(){
            let _this = this
           // created_at_min
           if (_this.profitOrderApplyFormData.time===undefined){
               this.$message.error("未选择时间");
           }
            _this.profitOrderApplyFormData.created_at_min =_this.profitOrderApplyFormData.time[0]
            _this.profitOrderApplyFormData.created_at_max =_this.profitOrderApplyFormData.time[1]
            request.post('/payment/mpshop/pay/profitorderapply', _this.profitOrderApplyFormData).then(ret => {
                if (ret.code === 1) {

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        mpShopDeliveryReceiveOrder(){
            this.loading = true;
            let _this = this
            request.get('/payment/mpshop/delivery/receive', {out_order_id:_this.delivery_receive_out_order_id}).then(ret => {
                if (ret.code === 1) {

                } else {
                    this.$message.error(ret.msg);
                }
            });
        }

    },
    computed: {
        queryParams: {
            set: function (newValue) {
                this.$store.commit('initQueryParams', newValue)
            },
            get: function (newValue) {
                return this.$store.state.queryParams
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.container {
   /* background-color: #797979;*/
}

.top {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 30px;
}

.table-title {
    height: 80px;
    font-size: 18px;
    color: #333333;
    font-weight: 900;
    line-height: 80px;
    border-bottom: 1px solid #eeeeee;
}

.table-title span {
    font-size: 14px;
    color: #666666;
    font-weight: 0;
    margin-left: 30px;
}

.box {
    padding: 30px 0 0 0;
    box-sizing: border-box;
}
.item {
    padding: 30px;
}

.item-top {
    border-bottom: 1px solid #eeeeee;
}
.mun {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;
    color: #333333;
}

.title {
    font-size: 16px;
    font-weight: 0;
    margin-bottom: 20px;
    font-size: 16px;
    color: #333333;
}
.btn {
    cursor: pointer;
    width: 56px;
    height: 30px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 30px;
    background-color: rgba(3, 18, 37, 0.1);
    margin-top: 20px;
}
.btn:hover {
    color: #ffffff;
    background-color: #409eff;
}
.common {
    font-size: 16px;
    color: #333333;
    text-align: center;
    cursor: pointer;
}
</style>
